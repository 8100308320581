import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Form,
  Card,
  Input,
  Space,
  Select,
  // DatePicker,
  message,
  Image
} from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
import { useDispatch, useSelector } from 'react-redux';
import { getAllMaterialslist } from '@/features/stockCard/redux/slice';
import {
  CreateNewProduct,
  getAllProductNames,
  getAllProductDetails,
  getAllUoms,
  getAllStageslist,
  getAllStageProcesses,
  getEquipaments
} from '../../redux/slice';
import {
  DeleteOutlined,
  PlusCircleFilled,
  HolderOutlined
} from '@ant-design/icons';
import truck from '@/features/qa/components/images/truck-delivery-done.gif';
import Layout from '@/common/Layout';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const { Option } = Select;

const createProductNewPage = () => {
  const [form] = Form.useForm();
  const { productNames, productDetails, Allequipements } = useSelector(
    (state) => state.product
  );

  const dispatch = useDispatch();
  const [truckShow, setTruckShow] = useState(false);
  const [uoms, setUoms] = useState([]);
  const [stagesList, setStagesList] = useState([]);
  const [stagesProcesses, setStagesProcesses] = useState([]);
  const [stages, setStages] = useState([
    { id: 0, stage_name: '', process: [], stage_yield_qty: '', uom: '' }
  ]);

  const onFinish = (values) => {
    values['stages'] = stages;
    // values['customer_details'] = values.customer_details;
    values['manufacturing_site'] = 'Graviti Pharmaceuticals Pvt. Ltd.';
    dispatch(CreateNewProduct(values)).then((response) => {
      if (response?.payload?.success) {
        setTruckShow(false);
        message.success(response?.payload?.message);
        history.push(`/bmr-create/${response?.payload?.data[0]?.id}`);
      } else {
        setTruckShow(false);
      }
    });
  };

  useEffect(() => {
    handleGetAllMaterialsList();
    handleGetEquipmets();
  }, []);

  useEffect(() => {
    handleGetProductNames();
    handleGetuoms();
    handleGetStages();
    handleGetStageProcess();
  }, []);

  const handleGetProductNames = () => {
    dispatch(getAllProductNames());
  };
  const handleGetStages = () => {
    dispatch(getAllStageslist()).then((response) => {
      if (response?.payload?.success) {
        setStagesList(response?.payload?.data);
      }
    });
  };

  const handleGetEquipmets = () => {
    dispatch(getEquipaments());
  };

  const handleGetStageProcess = () => {
    dispatch(getAllStageProcesses()).then((response) => {
      if (response?.payload?.success) {
        setStagesProcesses(response?.payload?.data);
      }
    });
  };
  const handleGetAllMaterialsList = () => {
    const payload = {};
    dispatch(getAllMaterialslist(payload));
  };

  const handleGetuoms = () => {
    const payload = {};
    dispatch(getAllUoms(payload)).then((response) => {
      if (response?.payload?.success) {
        setUoms(response?.payload?.data);
      }
    });
  };

  const handleGetProductDetails = (e) => {
    let payload = { product_type_code: e };
    dispatch(getAllProductDetails(payload));
  };

  const handleProductDetails = (e) => {
    let shelf_life = productDetails.filter(
      (data) => data.product_details === e
    );
    console.log(shelf_life);
    form.setFieldsValue({ shelf_life: shelf_life[0]?.shelf_life });
  };

  const handleAddStage = () => {
    setStages([
      ...stages,
      {
        id: stages.length,
        stage_name: '',
        process: [],
        stage_yield_qty: '',
        uom: ''
      }
    ]);
  };

  const handleRemoveExtra = (i) => {
    let formavalues = [...stages];
    formavalues.splice(i, 1);
    setStages(formavalues);
  };

  const onChangeStageprocess = (e, type, index) => {
    const list = [...stages];
    type == 'stage_yield_qty'
      ? (list[index][type] = e.target.value)
      : (list[index][type] = e);
    setStages(list);
  };

  const handleDrop = (droppedItem) => {
    if (!droppedItem.destination) return;
    var updatedList = [...stages];
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    setStages(updatedList);
    console.log(droppedItem);
  };

  return (
    <Layout sider={false}>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Card title={'ADD NEW PRODUCT'} className={styles.CustomPanel}>
          <Row gutter={24}>
            <Col xs={24} xl={12} span={12} md={24} sm={24}>
              <FormItem
                label="Product Name"
                name="is_product"
                rules={[
                  {
                    required: false,
                    message: 'Enter Theoretical Yield/Batch Size'
                  }
                ]}
                className={styles.customLable}
              >
                <Select
                  placeholder="Select Product Type"
                  onChange={(e) => handleGetProductDetails(e)}
                >
                  {productNames.map((item, index) => {
                    return (
                      <option value={item?.product_name_type} key={index}>
                        {item?.product_name}
                      </option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>
            <Col xs={24} xl={12} span={24} md={24} sm={24}>
              <FormItem
                label="Product Details"
                name="product_name"
                rules={[
                  {
                    required: true,
                    message: 'Enter detail name of the Product'
                  }
                ]}
                className={styles.customLable}
              >
                <Select
                  placeholder="Select Product Details"
                  onChange={(e) => handleProductDetails(e)}
                >
                  {productDetails?.map((item, index) => {
                    return (
                      <option value={item?.product_details} key={index}>
                        {item?.product_details}
                      </option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Ref. MFC#"
                className={styles.customLable}
                name="ref_mfc"
                rules={[
                  {
                    required: true,
                    message: 'Enter Ref MFC'
                  }
                ]}
              >
                <Input placeholder="Enter Ref. MFC#" />
              </FormItem>
            </Col>
            <Col xs={24} xl={12} span={12}>
              <FormItem
                label="Shelf life"
                name="shelf_life"
                rules={[
                  {
                    required: true,
                    message: 'Enter Shelf life'
                  }
                ]}
                className={styles.customLable}
              >
                <Input placeholder="Enter Shelf life" />
              </FormItem>
            </Col>
            <Col xs={24} xl={12} span={12}>
              <FormItem
                label="Manufacturing Site"
                name="manufacturing_site"
                rules={[
                  {
                    required: false,
                    message: 'Select Manufacturing Site'
                  }
                ]}
                className={styles.customLable}
              >
                <Select
                  showSearch
                  placeholder="Select  Manufacturing Site"
                  optionFilterProp="children"
                  defaultValue="Graviti Pharmaceuticals Pvt. Ltd."
                >
                  <option value="Graviti Pharmaceuticals Pvt. Ltd.">
                    Graviti Pharmaceuticals Pvt. Ltd.
                  </option>
                </Select>
              </FormItem>
            </Col>
            <Col xs={24} xl={12} span={12}>
              <FormItem
                label="Market / Customer Details"
                name="customer_details"
                rules={[
                  {
                    required: false,
                    message: 'Enter Market / Customer Details'
                  }
                ]}
                className={styles.customLable}
              >
                <Select
                  placeholder="Select Market/Customer Details"
                  defaultActiveFirstOption={true}
                  filterOption={true}
                  // defaultValue="USA"
                >
                  <Option value="India">India (Domestic)</Option>
                  <Option value="USA">USA</Option>
                </Select>
              </FormItem>
            </Col>
            <Col xs={24} xl={12} span={12} md={24} sm={24}>
              <FormItem
                label="BMR / BPR Number"
                name="bmr_bpr_number"
                rules={[
                  {
                    required: false,
                    message: 'Enter BMR/BPR Number'
                  }
                ]}
                className={styles.customLable}
              >
                <Input placeholder="Enter BMR/BPR Number" />
              </FormItem>
            </Col>
            {/* <Col xs={24} xl={12} span={12} md={24} sm={24}>
              <FormItem
                label="Effective Date"
                name="effective_date"
                rules={[
                  {
                    required: false,
                    message: 'Select Effective Date'
                  }
                ]}
                className={styles.customLable}
              >
                <DatePicker
                  placeholder="Select Effective Date"
                  style={{ width: '100%' }}
                />
              </FormItem>
            </Col> */}
            <Col xs={24} xl={12} span={12} md={24} sm={24}>
              <FormItem
                label="Theoretical Yield of Sifted & Milled Granules"
                name="theoretical_yield"
                rules={[
                  {
                    required: false,
                    message:
                      'Enter Theoretical Yield of Sifted & Milled Granules'
                  }
                ]}
                className={styles.customLable}
              >
                <Input placeholder="Enter Theoretical Yield of Sifted & Milled Granules" />
              </FormItem>
            </Col>
            <Col xs={24} xl={12} span={12} md={24} sm={24}>
              <FormItem
                label="Theoretical Yield/Batch Size"
                name="theoretical_batch_size"
                rules={[
                  {
                    required: false,
                    message: 'Enter Theoretical Yield/Batch Size'
                  }
                ]}
                className={styles.customLable}
              >
                <Input placeholder="Enter Theoretical Yield/Batch Size" />
              </FormItem>
            </Col>
            <Col xs={24} xl={12} span={12}>
              <FormItem
                label="Equipment Name"
                name="equipment_name"
                rules={[
                  {
                    required: false,
                    message: 'Select Equipment Name'
                  }
                ]}
                className={styles.customLable}
              >
                <Select showSearch placeholder="Select  Equipment Name">
                  {Allequipements?.map((item, index) => {
                    return (
                      <option key={index} value={item?.id}>
                        {item?.equipment_name}
                      </option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>
          </Row>
        </Card>
        <br />
        <Card title="ADD STAGES" className={styles.CustomPanel}>
          <DragDropContext onDragEnd={handleDrop}>
            <Droppable
              droppableId="list-container"
              className={`${styles.approver_line}`}
            >
              {(provided) => (
                <div
                  className="list-container"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {stages?.map((item, index) => {
                    return (
                      <Draggable
                        key={item.id}
                        draggableId={`${item.id}`}
                        index={index}
                      >
                        {(provided) => (
                          <>
                            <div
                              ref={provided.innerRef}
                              {...provided.dragHandleProps}
                              {...provided.draggableProps}
                            >
                              <Row gutter={24} key={index}>
                                <Col
                                  xs={24}
                                  xl={1}
                                  span={12}
                                  md={24}
                                  sm={24}
                                  style={{ marginTop: '33px' }}
                                >
                                  <HolderOutlined
                                    style={{ fontSize: '24px', color: 'black' }}
                                  />
                                </Col>
                                <Col xs={24} xl={4} span={12} md={24} sm={24}>
                                  <FormItem
                                    label={`Stage ${index + 1}`}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Select Stage'
                                      }
                                    ]}
                                    className={styles.customLable}
                                  >
                                    <Select
                                      showSearch
                                      placeholder="Select Stage"
                                      optionFilterProp="children"
                                      onChange={(e) =>
                                        onChangeStageprocess(
                                          e,
                                          'stage_name',
                                          index
                                        )
                                      }
                                      value={
                                        stages[index].stage_name
                                          ? stages[index].stage_name
                                          : ''
                                      }
                                    >
                                      {stagesList.map((item, index) => {
                                        return (
                                          <option
                                            value={item.stage_master_name}
                                            key={index}
                                          >
                                            {item.stage_master_name}
                                          </option>
                                        );
                                      })}
                                    </Select>
                                  </FormItem>
                                </Col>
                                <Col
                                  xs={24}
                                  xl={6}
                                  span={12}
                                  md={24}
                                  sm={24}
                                  // style={{ marginTop: '9px' }}
                                >
                                  <FormItem
                                    label="Standard Yield of this Stage"
                                    // name="stage"
                                    rules={[
                                      {
                                        required: false,
                                        message:
                                          'Enter Standard Yield of this Stage'
                                      }
                                    ]}
                                    className={styles.customLable}
                                  >
                                    <Input
                                      placeholder="Enter Standard Yield of this Stage"
                                      onChange={(e) =>
                                        onChangeStageprocess(
                                          e,
                                          'stage_yield_qty',
                                          index
                                        )
                                      }
                                      value={
                                        stages[index].stage_yield_qty
                                          ? stages[index].stage_yield_qty
                                          : ''
                                      }
                                    />
                                  </FormItem>
                                </Col>
                                <Col
                                  xs={24}
                                  xl={2}
                                  span={12}
                                  md={24}
                                  sm={24}
                                  // style={{ marginTop: '9px' }}
                                >
                                  <FormItem
                                    label="UOM"
                                    // name="stage"
                                    rules={[
                                      {
                                        required: false,
                                        message: 'Enter UOM'
                                      }
                                    ]}
                                    className={styles.customLable}
                                  >
                                    <Select
                                      showSearch
                                      placeholder="Select UOM"
                                      optionFilterProp="children"
                                      onChange={(e) =>
                                        onChangeStageprocess(e, 'uom', index)
                                      }
                                      value={
                                        stages[index].uom
                                          ? stages[index].uom
                                          : ''
                                      }
                                    >
                                      {uoms.map((item, index) => {
                                        return (
                                          <option value={item.id} key={index}>
                                            {item.uom_type}
                                          </option>
                                        );
                                      })}
                                    </Select>
                                  </FormItem>
                                </Col>
                                <Col xs={24} xl={6} span={12} md={24} sm={24}>
                                  <FormItem
                                    label="Process"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Select Stage'
                                      }
                                    ]}
                                    className={styles.customLable}
                                  >
                                    <Select
                                      showSearch
                                      placeholder="Select Process"
                                      optionFilterProp="children"
                                      mode="multiple"
                                      onChange={(e) =>
                                        onChangeStageprocess(
                                          e,
                                          'process',
                                          index
                                        )
                                      }
                                      value={
                                        stages[index].process.length
                                          ? stages[index].process
                                          : []
                                      }
                                    >
                                      {stagesProcesses.map((item, index) => {
                                        return (
                                          <option
                                            value={item.process_master_name}
                                            key={index}
                                          >
                                            {item.process_master_name}
                                          </option>
                                        );
                                      })}
                                    </Select>
                                  </FormItem>
                                </Col>
                                <Col xs={24} xl={2} span={12} md={24} sm={24}>
                                  <Space direction="horizental">
                                    {stages.length == index + 1 ? (
                                      <CustomButton
                                        className={styles.border_button}
                                        onClick={() => handleAddStage()}
                                        style={{ marginTop: '22px' }}
                                        type="primary"
                                        ghost
                                      >
                                        <PlusCircleFilled />
                                      </CustomButton>
                                    ) : (
                                      ''
                                    )}
                                    {index > 0 ? (
                                      <CustomButton
                                        className={styles.border_button}
                                        onClick={() => handleRemoveExtra(index)}
                                        style={{ marginTop: '22px' }}
                                      >
                                        <DeleteOutlined />
                                      </CustomButton>
                                    ) : (
                                      ''
                                    )}
                                  </Space>
                                </Col>
                              </Row>
                            </div>
                          </>
                        )}
                      </Draggable>
                    );
                  })}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Card>
        <br />
        <FormItem className="text-center">
          <Space>
            {truckShow ? (
              <Image
                src={truck}
                preview={false}
                style={{ width: '100px !important', height: '100px' }}
              />
            ) : (
              <CustomButton
                className={styles.inwardButton}
                htmlType="submit"
                type="primary"
              >
                Create
              </CustomButton>
            )}
            <CustomButton
              className={styles.inwardButton}
              type="primary"
              ghost
              onClick={() => history.back()}
            >
              Back
            </CustomButton>
          </Space>
        </FormItem>
      </Form>
    </Layout>
  );
};
export default createProductNewPage;
